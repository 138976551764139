import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { graphql, Link } from 'gatsby'

import Page from '../components/Page'
import Card from '../components/Card'
import Button from '../components/Button'
import Container from '../components/Container'
import Companies from '../components/Companies'
import Section from '../components/Section'

const CompaniesPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => (
  <Page
    title='Our Companies Shape our Success'
    background='images/pages/Companies.jpg'
  >
    <Container>
      <Section
        title='Our Partnerships'
        subtitle='We develop deep, lasting partnerships with our portfolio companies to help them succeed in achieving their mission. We deeply support our investments, nurturing the best investor-founder relationship.'
      >
        <Companies />
      </Section>
      <Section
        title='Highlights'
      >
        <Row>
          { edges.map((p, i) => (
            <Col md={4} sm={12} xs={12} key={i}>
              <Card
                title={p.node.frontmatter.title}
                date={p.node.frontmatter.date}
                description={p.node.frontmatter.teaser}
                path={p.node.frontmatter.path}
              />
            </Col>
          )) }
        </Row>
        <Row around='lg'>
          <Link to='/insights'>
            <Button appearance='primary'>View All Insights</Button>
          </Link>
        </Row>
      </Section>
    </Container>
  </Page>
)

export default CompaniesPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 3) {
      edges {
        node {
          id
          frontmatter {
            date
            path
            title
            teaser
          }
        }
      }
    }
  }
`
